import i18n from '@/libs/i18n/index'

export default [
    {
        name: i18n.t("Judge") || "Judge",
        label: i18n.t("Judge") || "Judge",
        name_ar: i18n.t("Judge") || "Judge",
        value: "3",
    },
    {
        name: i18n.t("Technical Judge") || "Technical Judge",
        label: i18n.t("Technical Judge") || "Technical Judge",
        name_ar: i18n.t("Technical Judge") || "Technical Judge",
        value: "4",
    },
]
