







































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";

import {
  BCard,
  BFormFile,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
} from "bootstrap-vue";
import {
  AppEditor,
  AppInput,
  AppFilePreview,
  AppForm,
  AppSelect,
  AppButton,
} from "@/components/form/index";
import { toFormData } from "@/utils/index";

import judgeActors from "./judgeActors";

interface Form {
  name: string;
  judge_job_title: string;
  judge_description: string;
  email: string;
  mobile: string;
  password: string;
  image: string;
  judge_image: string;
  roles: string[];
  actor: object;
  judge_title: string;
}

interface roleInterface {
  id: number;
  name: string;
}

@Component({
  name: "AddNewJudge",
  components: {
    AppInput,
    AppEditor,
    BCard,
    BFormGroup,
    BFormFile,
    AppFilePreview,
    AppForm,
    BFormCheckbox,
    AppSelect,
    BRow,
    BCol,
    AppButton,
  },
  data() {
    return {
      successMessage: null,
      id: "add-new-judges",
      form: {
        name: "",
        judge_job_title: "",
        judge_description: "",
        judge_title: "",
        email: "",
        mobile: "",
        password: "",
        image: null,
        judge_image: null,
        roles: [],
        actor: null,
      } as Form,
      changeUserPassword: true,
      actors: judgeActors,
    };
  },
  computed: {
    isLoading() {
      return (
        (this as any).$store.state.judges.isLoading.creating ||
        (this as any).$store.state.judges.isLoading.updating
      );
    },
    errorsBag() {
      return (this as any).$store.state.judges.errors;
    },
    singleData() {
      return (this as any).$store.state.judges.singleData;
    },
    doesErrorsExist() {
      return !!(
        (this as any).errors &&
        (this as any).errors.items &&
        (this as any).errors.items.length
      );
    },
    roles() {
      return this.$store.state.roles.data;
    },
  },
  created() {
    this.$store.dispatch("judges/resetModule");
    this.$store.dispatch("roles/fetchAllData");

    this.$route.query &&
      this.$route.query.uuid &&
      this.$store.dispatch("judges/fetchSingleItem", this.$route.query.uuid);
  },
  watch: {
    singleData: {
      handler(newVal, oldVal) {
        if (!newVal) return;
        this.changeUserPassword = false;
        const formData = JSON.parse(JSON.stringify(newVal));

        // console.log(formData.actor);
        // console.log(judgeActors);
        console.log(
          judgeActors?.filter((actor) => actor.value == formData.actor)?.[0]
        );
        this.form = {
          ...formData,
          actor: judgeActors?.filter(
            (actor) => actor.value == formData.actor
          )?.[0],
          roles: formData.roles.map((role: roleInterface) => ({
            ...role,
            name_ar: role.name,
            label: role.name,
          })),
        };
      },
      deep: true,
    },
  },
  methods: {
    generateImageSrc(image) {
      if (!image) return;

      if (image instanceof File) return URL.createObjectURL(image);

      return image;
    },
    changeUserPasswordHandler() {
      (this as any).changeUserPassword = !(this as any).changeUserPassword;
    },
    cancelHandler() {
      (this as any).$emit("cancel");
      (this as any).$router.push("/judges");
    },
    submitAndAddNewHandler() {
      // (this as any).$emit("submit-and-add-new");
      (this as any).handleSubmit(false);
    },
    removeImageHandler(key) {
      if (!key) return;
      (this as any).form[key] = null;
    },
    handleSubmit(goToListing = true) {
      const actionName = (this as any).singleData ? "updateData" : "createData";
      const formData = (this as any).form;
      if ((this as any).singleData) {
        // remove image from payload in case the user didnt edit the image
        if (formData.judge_image && typeof formData.judge_image === "string")
          formData.judge_image = null;

        if (formData.image && typeof formData.image === "string")
          formData.image = null;
      }

      (this as any).$store
        .dispatch(`judges/${actionName}`, toFormData(formData))
        .then(() => {
          (this as any).hideForm();
          (this as any).successMessage = (this as any).$t("successfully_saved");
          goToListing && (this as any).$router.push({ name: "judges" });
        });
    },
    hideForm() {
      (this as any).$bvModal.hide((this as any).id);
      (this as any).$store.dispatch("judges/resetModule");
      (this as any).form = {
        name: "",
        judge_job_title: "",
        judge_description: "",
        judge_title: "",
        email: "",
        mobile: "",
        password: "",
        image: null,
        judge_image: null,
        roles: [],
        actor: null,
      };
      (this as any).$emit("hide");
    },
  },
})
export default class AddNewJudge extends Vue {}
